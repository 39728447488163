<script setup lang="ts">
import { Product } from '@shopware-pwa/types'

const props = defineProps<{
  product: Product
}>()
const labels = computed(() => {
  return {
    new: Boolean(props.product.translated.customFields.custom_product_fields_new) || false,
    onSale: Boolean(props.product.translated.customFields.custom_product_fields_onsale) || false,
    soon: Boolean(props.product.translated.customFields.custom_product_fields_soon) || false,
    willBeReplaced: Boolean(props.product.translated.customFields.custom_product_fields_will_be_replaced) || false,
  }
})
</script>
<template>
  <ul v-animate="{ cluster: 'true' }" class="cul labels">
    <li v-if="labels.new" v-animate="{ animation: 'vertical-reveal' }" class="label">
      {{ $t('shop.listingPage.labels.new') }}
    </li>
    <li v-if="labels.onSale" v-animate="{ animation: 'vertical-reveal' }" class="label">
      {{ $t('shop.listingPage.labels.onSale') }}
    </li>
    <li v-if="labels.soon" v-animate="{ animation: 'vertical-reveal' }" class="label">
      {{ $t('shop.listingPage.labels.soon') }}
    </li>
    <li v-if="labels.willBeReplaced" v-animate="{ animation: 'vertical-reveal' }" class="label">
      {{ $t('shop.listingPage.labels.willBeReplaced') }}
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.labels {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  li {
    padding: 10px 14px;
    border-radius: 5px;
    background-color: var(--c-green-dark-100);
    color: var(--c-white);
  }
}
</style>
